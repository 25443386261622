import { useState, useEffect, useRef, useCallback } from 'react';
import { usePostHog } from 'posthog-js/react';

export interface UseSchedulePlaybackReturn {
  isMusicPlaying: boolean;
  isMusicFinished: boolean;
  deltaScheduledWServer: number | null;
  toggleMute: () => void;
  isMuted: boolean;
}

const useSchedulePlayback = (
  isMusicLoaded: boolean,
  audioContext: AudioContext | null,
  audioContextState: AudioContextState | null,
  musicBuffer: AudioBuffer | null,
  setIsMusicError: React.Dispatch<React.SetStateAction<boolean>>,
  syncStatus: string | null,
  ntpOffset: number | null,
  isEventLoading: boolean,
  isEventPassed: boolean,
  isArmed: boolean,
  isTimecode: boolean,
  eventTime: Date | null, // eventTime is Event Scheduled Time or TestTime in Test
  timecodeChannel: boolean,
  isPyro?: boolean
): UseSchedulePlaybackReturn => {
  // PlaybackDelta is the playback delta in seconds compared to the audioContext current time (that can be different than 0)
  // deltaScheduledWServer is the difference between the scheduled time (when the music needs to start) and the current time (synchronized w server)
  // const [audioBuffer, setAudioBuffer] = useState(null);

  // Music Status
  const [isMusicPlaying, setIsMusicPlaying] = useState<boolean>(false); // false means music is off initially
  const [isMusicFinished, setIsMusicFinished] = useState<boolean>(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isNodeReady, setIsNodeReady] = useState<boolean>(false);

  const musicNodeRef = useRef<AudioBufferSourceNode | null>(null);
  const gainNodeRef = useRef<GainNode | null>(null);
  const channelIndex = (channel: boolean) => (channel ? 1 : 0);

  const timeoutRefStart = useRef<ReturnType<typeof setTimeout> | null>(null);
  const timeoutRefEnd = useRef<ReturnType<typeof setTimeout> | null>(null);

  const [deltaScheduledWServer, setDeltaScheduledWServer] = useState<number | null>(null);

  const posthog = usePostHog();

  // Clean up function to stop music and clear timeouts
  const cleanupPlayback = useCallback(() => {
    console.log('Cleaning up playback...');
    if (musicNodeRef.current) {
      try {
        musicNodeRef.current.stop();
        musicNodeRef.current.disconnect();
      } catch (e) {
        // Ignore errors when stopping already stopped nodes
      }
      musicNodeRef.current = null;
    }

    if (timeoutRefStart.current) {
      clearTimeout(timeoutRefStart.current);
      timeoutRefStart.current = null;
    }

    if (timeoutRefEnd.current) {
      clearTimeout(timeoutRefEnd.current);
      timeoutRefEnd.current = null;
    }
    setIsNodeReady(false);
    setIsMusicPlaying(false);
  }, []);

  // Logic to add Mute & audioNode
  useEffect(() => {
    console.log(`Preparing Music Node...:
        musicBuffer:${musicBuffer !== null}
        audioContext: ${audioContext !== null}
        isMusicLoaded: ${isMusicLoaded}
        isEventLoading: ${isEventLoading}
        isEventPassed: ${isEventPassed}`);

    if (
      !musicBuffer ||
      !audioContext ||
      !isMusicLoaded ||
      isEventLoading ||
      isEventPassed
    ) {
      // If we lost the buffer, clean up old node
      cleanupPlayback();
      return;
    }
    setIsMusicPlaying(false);
    // Create audio nodes
    const newMusicNode = audioContext.createBufferSource();
    newMusicNode.buffer = musicBuffer;

    if (!gainNodeRef.current) {
      gainNodeRef.current = audioContext.createGain();
    }

    // Setup audio routing
    const splitter = audioContext.createChannelSplitter(2);
    const merger = audioContext.createChannelMerger(2);
    newMusicNode.connect(splitter);
    musicNodeRef.current = newMusicNode;

    // Configure channels based on timecode settings
    if (isTimecode) {
      if (isPyro) {
        // Timecode + Pyro mode
        splitter.connect(
          merger,
          channelIndex(!timecodeChannel),
          channelIndex(!timecodeChannel)
        );
        splitter.connect(
          merger,
          channelIndex(timecodeChannel),
          channelIndex(timecodeChannel)
        );
      } else {
        // Timecode without pyro
        splitter.connect(
          merger,
          channelIndex(!timecodeChannel),
          channelIndex(!timecodeChannel)
        );
        splitter.connect(
          merger,
          channelIndex(!timecodeChannel),
          channelIndex(timecodeChannel)
        );
      }
    } else {
      // No timecode
      splitter.connect(merger, 0, 0);
      splitter.connect(merger, 1, 1);
    }

    // Connect to gain node and output
    merger.connect(gainNodeRef.current).connect(audioContext.destination);

    // Restore previous mute state
    gainNodeRef.current.gain.value = isMuted ? 0 : 1;

    console.log('Music node ready');
    setIsNodeReady(true);
  }, [musicBuffer, isMusicLoaded, isEventLoading, isEventPassed, isNodeReady]);

  // Logic to launch Audio playback
  useEffect(() => {
    console.log(`Scheduling music playback...:
        isNodeReady:${isNodeReady}
        isArmed: ${isArmed}
        syncStatus: ${syncStatus}
        eventTime: ${eventTime}
        ntpOffset: ${ntpOffset}
        audioContextState: ${audioContextState}`);

    if (
      !isNodeReady ||
      !isArmed ||
      !audioContext ||
      !musicNodeRef.current ||
      syncStatus !== 'end' ||
      eventTime == null ||
      ntpOffset == null ||
      audioContextState !== 'running'
    ) {
      console.log('Not Ready to schedule Playback yet');
      return;
    }

    if (eventTime === null) {
      // Reset PlaybackDelta when eventTime is changed because eventTime can be testTime that can be null (when reset in interface)
      setDeltaScheduledWServer(null);
      return;
    }

    // If the scheduled time is in the future, schedule playback using audioContext's built
    try {
      // Calculate the
      const adjustedServerTime = new Date(Date.now() + ntpOffset);
      const deltaScheduledWServer =
        (new Date(eventTime).getTime() - adjustedServerTime.getTime()) / 1000;
      setDeltaScheduledWServer(deltaScheduledWServer);
      // const deltaAudioContextRef = deltaScheduledWServer + 2 * audioContext.currentTime;
      let playbackDelta: number;
      if (deltaScheduledWServer > 0) {
        // Starting music relative to the audioContext time
        playbackDelta = deltaScheduledWServer + audioContext.currentTime;
      } else {
        // Music is launched immediatly
        playbackDelta = deltaScheduledWServer;
      }

      console.log(`Calculated new deltas:
            audioContextState:${audioContextState}
            ntpOffset: ${ntpOffset}
            audioContext.currentTime: ${audioContext.currentTime}
            deltaScheduledWServer: ${deltaScheduledWServer}
            playbackDelta: ${playbackDelta}`);

      console.log('Scheduling music playback...');

      // Calculate timings
      const timeoutDurationForEnd =
        (deltaScheduledWServer + musicBuffer!.duration) * 1000;

      console.log(`Audio playback parameters:
          AudioContext time: ${audioContext.currentTime}
          Playback delta: ${playbackDelta}
          Delta scheduled vs server: ${deltaScheduledWServer}
          End timeout: ${timeoutDurationForEnd}ms`);

      if (timeoutDurationForEnd > 0) {
        if (playbackDelta > 0) {
          // Music hasn't started yet, schedule for future
          console.log(`Scheduling music to start at audio time ${playbackDelta}`);
          musicNodeRef.current.start(playbackDelta);

          timeoutRefStart.current = setTimeout(() => {
            console.log('Music start timeout triggered');
            posthog?.capture('music_started');
            setIsMusicPlaying(true);
          }, deltaScheduledWServer * 1000);
        } else {
          // Music should have already started, start from offset
          console.log(`Starting music now from offset ${-playbackDelta}`);
          musicNodeRef.current.start(0, -playbackDelta);
          setIsMusicPlaying(true);
        }

        // Schedule end of music
        timeoutRefEnd.current = setTimeout(() => {
          console.log('Music end timeout triggered');
          posthog?.capture('music_ended');
          setIsMusicPlaying(false);
          setIsMusicFinished(true);
        }, timeoutDurationForEnd);
      } else {
        // Music should have already ended
        console.log('Music should have already ended');
        setIsMusicPlaying(false);
        setIsMusicFinished(true);
      }
    } catch (error) {
      console.error('Error scheduling audio playback:', error);
      setIsMusicError(true);
    }

    console.log('Cleaning up playback');
    return cleanupPlayback;
  }, [isNodeReady, isArmed, syncStatus, eventTime, audioContextState]);

  // Logic to handle muting audioContext
  // Handle mute toggle
  const toggleMute = () => {
    if (gainNodeRef.current) {
      const newMuteState = !isMuted;
      setIsMuted(newMuteState);
      gainNodeRef.current.gain.value = newMuteState ? 0 : 1;
    }
  };

  return { isMusicPlaying, isMusicFinished, deltaScheduledWServer, toggleMute, isMuted };
};

export default useSchedulePlayback;
