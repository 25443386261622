// src/hooks/useEvent.js

import useEventInfo from './useEventInfo';
// import useNTPTime, { UseNTPTimeReturn } from './useNTPTime';
// import useEventAudio, { UseEventAudioReturn } from './useEventAudio';
import useSynchronization from './useSynchronization';
import useAudioBuffer from './useAudioBuffer';
import useSchedulePlayback from './useSchedulePlayback';
import { EventInfo } from '../types/eventTypes';

interface UseEventProps {
  eventIdentifier: string;
  audioContext: AudioContext | null;
  audioContextState: AudioContextState | null;
  isTestMode?: boolean;
  isPyroPage?: boolean;
}

interface UseEventReturn {
  eventInfo: EventInfo | null;
  scheduledTime: Date | null;
  isArmed: boolean;
  testTime: Date | null;
  eventError: any;
  isEventPassed: boolean;
  isEventLoading: boolean;
  isSynchronized: boolean;
  isMusicPlaying: boolean;
  isMusicFinished: boolean;
  toggleMute: () => void;
  isMuted: boolean;
  isMusicError: boolean;
  isNetworkError: boolean;
  ntpOffset: number | null;
}

const useEvent = (params: UseEventProps): UseEventReturn => {
  const {
    eventIdentifier,
    audioContext,
    audioContextState,
    isTestMode = false,
    isPyroPage = false,
  } = params;

  // Fetch event information
  const {
    eventInfo,
    scheduledTime,
    testTime,
    eventError,
    isArmed,
    isEventPassed,
    isEventLoading,
  } = useEventInfo(isPyroPage ? `pyro/${eventIdentifier}` : `public/${eventIdentifier}`);

  // Determine the event time
  const eventTime = isTestMode ? testTime : scheduledTime;
  const isEventArmed = isTestMode ? true : isArmed; // launch music if isArmed when not in test Mode

  // Handle time synchronization
  // const {
  //   isSynchronized,
  //   isNetworkError,
  //   ntpOffset,
  //   playbackDelta,
  //   deltaScheduledWServer,
  // } = useNTPTime(
  //   audioContext,
  //   audioContextState,
  //   eventTime,
  //   isEventLoading,
  //   isEventPassed
  // );

  // Synchronization with Server
  const { isSynchronized, isNetworkError, ntpOffset, syncStatus } = useSynchronization();

  // Manage audio playback

  // Prepare audio parameters
  const musicPath = eventInfo?.musicPath ?? '';
  const isTimecode = eventInfo?.isTimecode ?? false;
  const timecodeChannel = eventInfo?.timecodeChannel ?? false;

  const { isMusicLoaded, isMusicError, setIsMusicError, musicBuffer } = useAudioBuffer(
    musicPath,
    audioContext,
    isEventLoading
  );

  const { isMusicPlaying, isMusicFinished, toggleMute, isMuted } = useSchedulePlayback(
    isMusicLoaded,
    audioContext,
    audioContextState,
    musicBuffer,
    setIsMusicError,
    syncStatus,
    ntpOffset,
    isEventLoading,
    isEventPassed,
    isEventArmed,
    isTimecode,
    eventTime, // eventTime is Event Scheduled Time or TestTime in Test
    timecodeChannel,
    isPyroPage
  );
  // // Manage audio playback
  // const { isMusicPlaying, isMusicFinished, toggleMute, isMuted, isMusicError } =
  //   useEventAudio(
  //     musicPath,
  //     audioContext,
  //     playbackDelta,
  //     deltaScheduledWServer,
  //     isTimecode,
  //     timecodeChannel,
  //     isEventArmed,
  //     isEventLoading,
  //     isPyroPage // For timecode splitting
  //   );

  return {
    eventInfo,
    scheduledTime,
    testTime,
    eventError,
    isArmed,
    isEventPassed,
    ntpOffset,
    isSynchronized,
    isEventLoading,
    isMusicPlaying,
    isMusicFinished,
    toggleMute,
    isMuted,
    isMusicError,
    isNetworkError,
  };
};

export default useEvent;
