import { useState, useEffect, useRef } from 'react';

export interface UseAudioBufferReturn {
  isMusicLoaded: boolean;
  isMusicError: boolean;
  setIsMusicError: React.Dispatch<React.SetStateAction<boolean>>;
  musicBuffer: AudioBuffer | null;
}

const useAudioBuffer = (
  musicPath: string | null,
  audioContext: AudioContext | null,
  isEventLoading: boolean
): UseAudioBufferReturn => {
  const [musicBuffer, setMusicBuffer] = useState<AudioBuffer | null>(null);

  // Music Status
  const [isMusicLoaded, setIsMusicLoaded] = useState<boolean>(false);
  const [isMusicError, setIsMusicError] = useState<boolean>(false);

  const timeoutRefStart = useRef<ReturnType<typeof setTimeout> | null>(null);
  const timeoutRefEnd = useRef<ReturnType<typeof setTimeout> | null>(null);

  // State to hold the current nodes
  const musicNodeRef = useRef<AudioBufferSourceNode | null>(null);
  const channelIndex = (channel: boolean) => (channel ? 1 : 0);

  // Clean up function to stop music and clear timeouts
  const cleanupPlayback = () => {
    if (musicNodeRef.current) {
      try {
        musicNodeRef.current.stop();
        musicNodeRef.current.disconnect();
      } catch (e) {
        // Ignore errors when stopping already stopped nodes
      }
      musicNodeRef.current = null;
    }

    if (timeoutRefStart.current) {
      clearTimeout(timeoutRefStart.current);
      timeoutRefStart.current = null;
    }

    if (timeoutRefEnd.current) {
      clearTimeout(timeoutRefEnd.current);
      timeoutRefEnd.current = null;
    }
  };

  // Logic for preloading Audio
  useEffect(() => {
    // Check if required parameters are available
    if (isEventLoading || !musicPath || !audioContext) {
      console.log('Event not loaded yet');
      return;
    }
    const preloadMusic = (url: string, retryCount: number = 3) => {
      // console.log(url);
      fetch(url)
        .then(response => response.arrayBuffer())
        .then(arrayBuffer => {
          // console.log(url)
          if (arrayBuffer.byteLength === 0) {
            throw new Error('Array buffer is empty.');
          }
          // // Check if the audio context is suspended
          // if (audioContext.state === 'suspended') {
          //   // console.log('Audio Context Suspended :',arrayBuffer.byteLength)
          //   return audioContext.resume().then(() => arrayBuffer);
          // }
          // console.log('Audio Context was not suspended', arrayBuffer.byteLength);
          return arrayBuffer;
        })
        .then(arrayBuffer => audioContext.decodeAudioData(arrayBuffer))
        .then(decodedAudioBuffer => {
          setMusicBuffer(decodedAudioBuffer);
          setIsMusicLoaded(true);
          console.log('Music preloaded successfully');

          // setAudioDuration(decodedAudioBuffer.duration)
          // console.log('Music preloaded |o/')
        })
        .catch(error => {
          // console.error('Error preloading audio:', error);
          if (retryCount > 0) {
            // console.log(`Retrying... ${retryCount} attempts left`);
            // console.log(url)
            setTimeout(() => preloadMusic(url, retryCount - 1), 10000); // Retry after 10 seconds
          } else {
            setIsMusicError(true);
          }
        });
    };

    preloadMusic(musicPath);

    return cleanupPlayback;
  }, [musicPath, audioContext]);

  return {
    isMusicLoaded,
    isMusicError,
    setIsMusicError,
    musicBuffer,
  };
};

export default useAudioBuffer;
