import { useState, useRef, useEffect } from 'react';
import { BASE_URL } from '../utils/API';
import io, { Socket } from 'socket.io-client';
import { set } from 'date-fns';
const timesync = require('timesync');

const TIMEOUT_NETWORK_ISSUE = 60000; // ms - If not able to synchronize in a 1 min laps, then network issue

export interface UseSynchronizationReturn {
  isSynchronized: boolean;
  isNetworkError: boolean;
  ntpOffset: number | null;
  syncStatus: string | null;
}

const useSynchronization = (): UseSynchronizationReturn => {
  const [ntpOffset, setNtpOffset] = useState<number | null>(null);

  const [isSynchronized, setIsSynchronized] = useState<boolean>(false);
  const [isNetworkError, setIsNetworkError] = useState<boolean>(false);

  const [syncStatus, setSyncStatus] = useState<string | null>(null);
  const [syncAttempts, setSyncAttempts] = useState<number>(0);

  const socketRef = useRef<Socket | null>(null);
  const tsRef = useRef<any>(null);
  const syncIntervalRef = useRef<number | undefined>(undefined);

  // Setup WebSocket and TimeSync
  useEffect(() => {
    console.log('Setting up websocket and timesync');
    const wsBaseUrl = BASE_URL.replace(/^http/, 'ws');
    const socket = io(wsBaseUrl, { path: '/socket.io' });
    socketRef.current = socket;

    const ts = timesync.create({
      server: socket,
      interval: null, // Disable automatic synchronization
    });
    tsRef.current = ts;

    ts.on('sync', function (state: string) {
      console.log('sync ' + state + '');
      setSyncStatus(state);
    });

    ts.on('change', function (newntpOffset: number) {
      console.log(`ntpOffset changed : ${newntpOffset} ms`);
      setNtpOffset(newntpOffset);
    });

    ts.send = function (socket: Socket, data: any, timeout: number) {
      console.log('send', data);
      return new Promise<void>(function (resolve, reject) {
        var timeoutFn = setTimeout(reject, timeout);
        socket.emit('timesync', data, function () {
          clearTimeout(timeoutFn);
          resolve();
        });
      });
    };

    socket.on('timesync', function (data) {
      ts.receive(null, data);
    });

    return () => {
      if (ts) ts.destroy();
      if (socket) socket.disconnect();
      setSyncStatus(null); // Reset sync status
    };
  }, [BASE_URL]);

  useEffect(() => {
    // tsRef is loaded
    console.log('Immediate sync');
    tsRef.current.sync();
  }, []);

  // Handle visibility change - re-sync when page becomes visible
  useEffect(() => {
    const handleVisibilityChange = () => {
      // console.log('UseNTP : Page is active!')
      if (!document.hidden && tsRef.current) {
        console.log('Page is active, triggering sync');
        tsRef.current.sync();
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  // Handle sync status changes
  useEffect(() => {
    if (syncStatus === 'end') {
      setIsSynchronized(true);

      // Only schedule additional sync if we have fewer than 1 attempt
      if (syncAttempts < 1 && !syncIntervalRef.current) {
        console.log('Scheduling additional sync attempt:', syncAttempts);
        syncIntervalRef.current = window.setInterval(() => {
          if (syncAttempts < 1 && tsRef.current) {
            tsRef.current.sync();
            setSyncAttempts(attempts => attempts + 1);
          } else {
            if (syncIntervalRef.current !== undefined) {
              window.clearInterval(syncIntervalRef.current);
              syncIntervalRef.current = undefined;
            }
          }
        }, 5000);
      }
    }
    return () => {
      if (syncIntervalRef.current) {
        clearInterval(syncIntervalRef.current);
        syncIntervalRef.current = undefined;
      }
    };
  }, [syncStatus, syncAttempts]);

  // If there is no network, the sync does not work
  useEffect(() => {
    // Checking network issue
    const timeoutId = setTimeout(() => {
      if (!isSynchronized) {
        setIsNetworkError(true);
      }
    }, TIMEOUT_NETWORK_ISSUE);

    return () => clearTimeout(timeoutId);
  }, [isSynchronized]);

  return {
    isSynchronized,
    isNetworkError,
    ntpOffset,
    syncStatus,
  };
};

export default useSynchronization;
