import { useCallback } from 'react';
import { API } from '../../../utils/API';

import useTriggerNotification from './useTriggerNotification';

const useLaunchEvent = (
  eventId: string,
  isTestMode: boolean,
  setIsLaunched: (isLaunched: boolean) => void
) => {
  const { triggerWebPushNotification } = useTriggerNotification();

  const updateEventLaunchedTime = useCallback(
    async (
      delaySec: number | null,
      // isEventLoading: boolean,
      isArmed: boolean,
      isLaunched?: boolean
    ) => {
      // if (isEventLoading) {
      //   console.log("Can't update event, event is loading");
      //   return;
      // }
      try {
        if (!isTestMode) {
          // if Public & Armed, we change the scheduled Time & isArmed in the database
          if (delaySec !== null && delaySec >= 0) {
            console.log(`delaySec ${delaySec}`);
            await API.patch(`/api/v1/events/${eventId}`, {
              scheduledDelay: delaySec,
              isArmed: isArmed,
              generatePublicLink: false,
            });
            setIsLaunched(isArmed);
          } else {
            console.log("Can't update, scheduledTime is null");
            return;
          }
          // triggerWebPushNotification(eventId);
        } else {
          // console.log(`New Test Time : ${delaySec} seconds`);
          //  testTime mode
          await API.patch(`/api/v1/events/${eventId}`, {
            testDelay: delaySec && delaySec >= 0 ? delaySec : 0,
            generatePublicLink: false,
          });

          // optionally
          if (isLaunched !== undefined) {
            // isLaunched is provided
            setIsLaunched(isLaunched);
          } else {
            setIsLaunched(true);
          }
        }
      } catch (err: any) {
        console.error('Error updating scheduled/test time:', err);
        alert(`Failed to update scheduled time: ${err.message}`);
      }
    },
    [eventId, isTestMode]
  );

  return { updateEventLaunchedTime };
};

export default useLaunchEvent;
